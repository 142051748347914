import React, { Component } from 'react';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';


export class FloorplanSlider extends Component {

    static getDerivedStateFromProps(nextProps, prevState) {
        var myfloorplanfolder = process.env.PUBLIC_URL + '/downloads/floorplans/';

        if (nextProps.showPane !== prevState.showPane) {
            return nextProps.showPane === true ? { showPane: true, src: myfloorplanfolder + nextProps.showInfo.d2P_FloorPlan, base64FloorPlan: nextProps.base64FloorPlan } : { showPane: false };
        }
        return null;
    }

    constructor(props) {
        super(props);
        this.state = {
            showPane: false,
            src: "https://dev.myshowonline.com/assets/floorplans/" + props.showInfo.d2P_FloorPlan,
            errored: false,
            base64FloorPlan: '',
            comingsoonsrc: process.env.PUBLIC_URL + '/downloads/floorplans/comingsoon.jpg'
        }
    }

    componentDidMount() {
        
    }

    componentDidUpdate(prevProps) {
        //console.log(this.state.base64FloorPlan);

        //this.loadFloorPlanFromBlob();
        //if (prevProps.location.state.showcode !== this.props.location.state.showcode) {
        //    this.loadFloorPlanFromBlob();
        //}


    }

    hidePane = () => {
        this.setState({
            showPane: false
        });

        this.props.hidePane();
    }

    loadFloorPlanFromBlob = () => {
        //var decodedStringBtoA = 'Hello World!';

        //// Encode the String
        //var encodedStringBtoA = btoa(decodedStringBtoA);
        //console.log(encodedStringBtoA);

        //var decodedStringAtoB = atob(encodedStringBtoA);
        //console.log(decodedStringAtoB);

        this.state.base64data = btoa(this.state.blobData)

        //var blob = new Blob([this.state.blobData], { type: "image/jpeg" })
        //var blob = new Blob([this.state.blobData], { type: "application/pdf" })

        //this.state.blobUrl = URL.createObjectURL(blob);
        //console.log(this.state.blobUrl);

        //const fileReaderInstance = new FileReader();
        //fileReaderInstance.readAsDataURL(blob);

        //var base64data = fileReaderInstance.result;
        //console.log(base64data);

        //fileReaderInstance.onload = () => {
        //    base64data = fileReaderInstance.result;
        //    console.log(base64data);
        //}

        //src={this.state.src}

    }

    onError = () => {
        if (!this.state.errored) {
            this.setState({
                src: process.env.PUBLIC_URL + '/downloads/floorplans/comingsoon.jpg',
                errored: true,
            });
        }
    }

    render() {
        

        return (
            <SlidingPane
                className='some-custom-class'
                overlayClassName='showCard'
                isOpen={this.state.showPane}
                title={this.props.showInfo.name + ' Floorplan'}
                onRequestClose={this.hidePane}
                closeIcon={<i className="fas fa-chevron-right float-left"></i>}
            >
                <div className="slide-pane-body scrollableDiv">

                    <div className="row">
                        {
                            this.state.base64FloorPlan &&
                            <img id='base64image' src={`data:image/jpeg;base64,${this.state.base64FloorPlan}`} />
                        }
                        {
                            !this.state.base64FloorPlan &&
                            <img id='comingsoon' src={this.state.comingsoonsrc} />
                        }
                    </div>
                </div>

                <div className="modal-footer">
                    <div className="form-group col-12 padding-25-10">
                        <button className="btn btn-primary btn-lg margin-left-15" onClick={this.hidePane}>Close</button>
                    </div>
                </div>
            </SlidingPane>
        );
    }
}