import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import Axios from '../../config/axios';
import { toast, confirm } from '@rickylandino/react-messages';
import ExhibitorSearch from './ExhibitorSearch';
import Globals from '../../config/globals';

function ProcessOnsiteOrderTab(props) {
    const [state, setState] = useState({
        selectedShow: props.selectedShow,
        selectedShowName: props.selectedShowName,
        onsiteOrderContact: '',
        formFields: {},
        lrprice: ''
    });

    useEffect(() => {

    }, []);

    useEffect(() => {
        setState({
            ...state,
            selectedShow: props.selectedShow,
            selectedExhibitorId: '',
            formFields: {
                contact: '',
                phone: '',
                email: ''
            },
            lrprice: '',
            lrpriceother: ''
        });

    }, [props.selectedShow]);

    function handleSearchReturn(exhibitorId, exhibitorName) {
        setState({
            ...state,
            selectedExhibitorId: exhibitorId,
            selectedExhibitorName: exhibitorName,
            resetFields: false
        });
    }

    function handleResetSelections(e) {
        e.preventDefault();

        setState({
            ...state,
            resetFields: true,
            selectedExhibitorId: '',
            lrprice: '',
            lrpriceother: '',
            formFields: {}
        });
    }

    function handleInputChange(e) {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setState({
            ...state,
            formFields: { ...state.formFields, [name]: value }
        });
    }

    function handleLRPriceChange(e) {
        const target = e.target;
        //const value = target.type === 'checkbox' ? target.checked : target.value;
        //const name = target.name;

        console.log(target.type);
        if (target.type === 'radio') {
            if (e.target.value == '$250' || e.target.value == 'Free') {
                setState({
                    ...state,
                    lrprice: e.target.value,
                    lrpriceother: ''
                });
            }
            else {
                //this indicates Other pricing
                setState({
                    ...state,
                    lrprice: e.target.value
                });
            }
        }
        else if (target.type === 'text') {
            setState({
                ...state,
                lrpriceother: e.target.value,
                lrprice: 'Other'
            });
        }

        //console.log("here in useEffect for props.resetFields: ", props.resetFields);
    }

    function handleProcessOrder(e) {
        e.preventDefault();

        if (state.formFields.contact === '') {
            toast.error("You must specify Contact Name before submitting an online order");

            return;
        }

        //Good to go - process order
        var mylrprice = '';
        if (state.lrpriceother != '') {
            mylrprice = state.lrpriceother;
        }
        else {
            mylrprice = state.lrprice;
        }

        let onlineOrder = {
            Id: parseInt(state.selectedExhibitorId),
            showcode: parseInt(state.selectedShow),
            contact: state.formFields.contact,
            phone: state.formFields.phone,
            email: state.formFields.email
        };

        let postdata = {
            reqExhibitor: state.selectedExhibitorName,
            currentuser: "Lead Retrieval Administrator",
            name: state.selectedShowName,
            lrprice: mylrprice,
            onlineOrdersModel: onlineOrder
        }

        Axios.post(`/api/ProcessLeadRetrievalOrder`, postdata
        ).then(response => {
            if (response.data) {
                setState({
                    ...state,
                    resetFields: true,
                    selectedExhibitorId: '',
                    lrprice: '',
                    lrpriceother: '',
                    formFields: {}
                });

                toast.success("Lead Retrieval order successfully submitted for " + state.selectedExhibitorName + " for " + state.selectedShowName);
            }
        }).catch(error => {
            console.log(error);

            toast.error("There was a problem processing the lead retrieval order for " + state.selectedExhibitorName + " for " + state.selectedShowName);
        });
    }

    return (
        <div className="code-box-demo">
            <div className="row">
                <label className="form-label mt-1">Search for Exhibitor:&nbsp;</label>
                <ExhibitorSearch {...props} resetFields={state.resetFields} handleSearchReturn={handleSearchReturn} />
            </div>

            {state.selectedExhibitorId == "" ?
                <div className="form-group col-lg-12 row">
                    <p>&nbsp;</p>
                    <h1>Select an exhibitor to process an on site order</h1>
                </div>
                :
                <>
                    <p>&nbsp;</p>
                    <div className="form-group col-lg-6">
                        <label className="form-label">Contact Name</label>
                        <input type="text" className="form-control-custom" name="contact" value={state.formFields.contact} onChange={handleInputChange} />
                    </div>
                    <div className="form-group col-lg-6">
                        <label className="form-label">Phone</label>
                        <input type="text" className="form-control-custom" name="phone" value={state.formFields.phone} onChange={handleInputChange} />
                    </div>
                    <div className="form-group col-lg-6">
                        <label className="form-label">Email</label>
                        <input type="text" className="form-control-custom" name="email" value={state.formFields.email} onChange={handleInputChange} />
                    </div>
                    <div className="form-group form-inline col-lg-12" onChange={handleLRPriceChange}>
                        <label className="form-label">Price:</label>&nbsp;&nbsp;
                        <input type="radio" name="lrprice" value="$250" checked={state.lrprice === "$250"} />&nbsp;<label className="form-label">$250</label>&nbsp;&nbsp;&nbsp;
                        <input type="radio" name="lrprice" value="Free" checked={state.lrprice === "Free"} />&nbsp;<label className="form-label">Free</label>&nbsp;&nbsp;&nbsp;
                        <input type="radio" name="lrprice" value="Other" checked={state.lrprice === "Other"} />&nbsp;<label className="form-label">Other</label>&nbsp;&nbsp;&nbsp;
                        <input type="text" className="form-control-custom" name="lrpriceother" value={state.lrpriceother} />
                    </div>
                    <div className="col-lg-12">
                        <button className="btn btn-danger btn-lg" onClick={handleProcessOrder}> Process Order</button>

                        <span className="form-group col-lg-2 a" onClick={handleResetSelections}><u>Reset Selections</u></span>
                    </div>
                </>
            }
        </div>
    );
}

export default withRouter(ProcessOnsiteOrderTab);