import React, { Component } from 'react';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import Moment from 'moment';

import pcard1 from '../../../img/pcard1.png';
import pcard2 from '../../../img/pcard2.png';

export class BoothFurnishingsInfoSlider extends Component {

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.showPane !== prevState.showPane) {
            return nextProps.showPane === true ? { showPane: true } : { showPane: false };
        }
        return null;
    }

    constructor(props) {
        super(props);
        this.state = {
            showPane: false
        }
    }

    hidePane = () => {
        this.setState({
            showPane: false
        });

        this.props.hidePane();
    }

    render() {

        return (
            <SlidingPane
                className='some-custom-class'
                overlayClassName='showCard'
                isOpen={this.state.showPane}
                title='Booth Furnishings Information'
                onRequestClose={this.hidePane}
                closeIcon={<i className="fas fa-chevron-right float-left"></i>}
            >
                <div className="slide-pane-body scrollableDiv">
                    <h3 className="text-center">{this.props.showInfo.name}</h3>
                    <div>
                        <b className="text-danger">Booth Furnishings Deadline: {Moment(this.props.showInfo.bfDeadline).format('LL')}</b>
                    </div>
                    <div className="text-center">
                        <h4>Booth Size Varies by Location</h4>
                    </div>
                    <div>
                        The following will be supplied automatically:
                        <ul>
                            <li>One 8' high back wall drape: Royal Blue with Gray center panel</li>
                            <li>Two 3' high side drapes: Royal Blue</li>
                            <li>Booth carpet: Tuxedo</li>
                            <li>2 Chairs</li>
                            <li>1 Wastebasket</li>
                        </ul>
                    </div>

                    <div>
                        The following items are furnished <b>at no charge</b>.
                        Use the enclosed forms to order properly.
                        <ul>
                            <li>One - Company Name Sign</li>
                            <li>Six - Easel backed product identification signs (7" x 11")</li>
                        </ul>
                    </div>

                    <div>
                        <h3>	
                            BOOTH COLORS ARE AS FOLLOWS:
                        </h3>
                        <div className="row">
                            <div className="col col-3">
                                <b>Aisle Carpet:</b>
                            </div>
                            <div className="col col-9">
                                Tuxedo
                            </div>

                            <div className="col col-3">
                                <b>Booth Carpet:</b>
                            </div>
                            <div className="col col-9">
                                Tuxedo
                            </div>

                            <div className="col col-3">
                                <b>Drapes:</b>
                            </div>
                            <div className="col col-9">
                                Back Drapes are Royal Blue with Gray Center Panels
                            </div>
                        </div>
                    </div>

                    <div className="mt-4">
                        Drape supports should be used for hanging only lightweight items such as signs with the hooks we supply. Display boards should be built to stand on the floor or on the table.
                    </div>

                    <div>
                        <b>NOTE: All signs will remain the property of the exhibitor at the close of the show.</b>
                    </div>
                </div>

                <div className="modal-footer">
                    <div className="form-group col-12 padding-25-10">
                        <button className="btn btn-primary btn-lg margin-left-15" onClick={this.hidePane}>Close</button>
                    </div>
                </div>
            </SlidingPane>
        );
    }
}