import React, { Component } from 'react';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import { EditorState, ContentState, convertToRaw, convertFromHTML } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import '../../../css/editor.css';
import Axios from '../../../config/axios';
import { smallBox, SmartMessageBox } from "../../../common/utils/functions";
import spinnerLarge from '../../../img/spinnerLarge.gif';

export class LeadRetrievalSendEmailSlider extends Component {
    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.showPane !== prevState.showPane) {
            return nextProps.showPane === true ? { showPane: true } : { showPane: false };
        }

        return null;
    }

    constructor(props) {
        super(props);
        this.state = {
            editorState: "",
            formFields: {
                recipient: '',
                subject: 'Design-2-Part Show Lead Retrieval Setup Instructions',
                bodycontent: ''
            },
            emailtext: "Electronic lead retrieval setup instructions for the Design-2-Part Show:<br /><br />" +
                "To get started, please make sure to download the D2P Shows app, available in the App Store and on Google Play, for your Apple or Android devices. You can use the links below, or simply search \"D2P Shows\" in the Apple or Google Play store.<br /><br />" +
                "iOS (iPhone) -  https://itunes.apple.com/us/app/d2p-shows/id845230786?mt=8<br />" +
                "Android - https://play.google.com/store/apps/details?id=com.d2p.d2pshows<br /><br />" +
                "When you arrive at the show office for check-in, D2P personnel will activate your device(s) and sync them to your online database.<br /><br />" +
                "Thanks,<br />" +
                "Mark Schmidt<br />" +
                "Director of Marketing",
            processingSubmitOrder: false
        }
    }

    componentDidMount() {
        this.setState({
            editorState: EditorState.createWithContent(
                ContentState.createFromBlockArray(
                    convertFromHTML(this.state.emailtext)
                )
            )
        });
    }

    hidePane = () => {
        this.setState({
            showPane: false
        });

        this.props.hidePane();
    }

    onEditorStateChange = (editorState) => {
        this.setState({
            editorState,
        }, () => this.isDirty = true);
    };

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({ formFields: { ...this.state.formFields, [name]: value } }, () => {
            if (JSON.stringify(this.state.formFields) !== JSON.stringify(this.formFields)) {
                this.isDirty = true;  // dirty
            }
        });
    }

    handleSubmit = (e) => {
        e.preventDefault();

        this.setState({
            processingSubmitOrder: true
        });

        this.state.formFields.bodycontent = draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()))
        let postdata = this.state.formFields;

        if (postdata.recipient === '' ||
            postdata.subject === '' ||
            postdata.bodycontent === ''
        ) {
            this.setState({
                processingSubmitOrder: false
            });

            smallBox({
                title: "Notice",
                content: "<i class='fa fa-clock-o'></i> <i>Please fill out all required fields.</i>",
                color: "#C46A69",
                iconSmall: "fa fa-times fa-2x fadeInRight animated",
                timeout: 4000
            });
        } else {
            Axios.post(`/api/SendInstructionsEmail`, postdata
            ).then(response => {
                this.setState({
                    processingSubmitOrder: false
                });

                if (response.data) {
                    smallBox({
                        title: "Notice",
                        content: "<i class='fa fa-clock-o'></i> <i>Instructions Email Sent.</i>",
                        color: "#659265",
                        iconSmall: "fa fa-check fa-2x fadeInRight animated",
                        timeout: 4000
                    });
                }
            }).catch(error => {
                console.log(error);

                this.setState({
                    processingSubmitOrder: false
                });

                smallBox({
                    title: "Notice",
                    content: "<i class='fa fa-clock-o'></i> <i>Something went wrong, please try again later or call Karen C. at 800-225-4535 ext. 138 or <a href=\"mailto: karenc@d2p.com\">karenc@d2p.com</a>.</i>",
                    color: "#C46A69",
                    iconSmall: "fa fa-times fa-2x fadeInRight animated",
                    timeout: 4000
                });
            });
        }
    }

    render() {
        const { editorState } = this.state;

        return (
            <SlidingPane
                className='some-custom-class w-75'
                overlayClassName='showCard'
                isOpen={this.state.showPane}
                title='Email Lead Retrieval Instructions'
                onRequestClose={this.hidePane}
                closeIcon={<i className="fas fa-chevron-right float-left"></i>}
            >
                <div className="slide-pane-body scrollableDiv">
                    <div className="row">
                        <div className="form-group col-lg-12">
                            <label className="form-label">To</label>
                            <input type="text" name="recipient" value={this.state.formFields.recipient || ''} onChange={this.handleInputChange} className="form-control-custom" />
                        </div>

                        <div className="form-group col-lg-12">
                            <label className="form-label">Subject</label>
                            <input type="text" name="subject" value={this.state.formFields.subject || ''} onChange={this.handleInputChange} className="form-control-custom" />
                        </div>

                        <div className="form-group col-lg-12">
                            <label className="form-label">Email Content</label>
                            <Editor editorState={this.state.editorState}
                                onEditorStateChange={this.onEditorStateChange}
                                wrapperClassName="demo-wrapper"
                                editorClassName="editor-textarea"
                            />
                        </div>
                    </div>
                </div>

                <div className="modal-footer">
                    <div className="form-group col-12 padding-25-10">
                        <button className="btn btn-danger btn-lg" onClick={this.handleSubmit} disabled={this.state.processingSubmitOrder}>Send Email</button>
                        <button className="btn btn-primary btn-lg margin-left-15" onClick={this.hidePane}>Close</button>
                        {this.state.processingSubmitOrder &&
                            <img src={spinnerLarge} alt="D2P Busy" className="margin-left-15" style={{ height: '30px', width: '30px' }} />
                        }
                    </div>
                </div>
            </SlidingPane>
        );
    }
}