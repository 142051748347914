import React, { Component, Fragment } from 'react';
import Card from 'react-bootstrap/Card';
import '../../../css/card.css';

import Axios from '../../../config/axios';
import Globals from '../../../config/globals';

import { Modal } from 'react-bootstrap';

import googlePlayImg from '../../../img/g_play.png';
import appStoreImg from '../../../img/a_store.png';

import { smallBox, SmartMessageBox } from "../../../common/utils/functions";

import NumberFormat from 'react-number-format';

import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';

import * as ShowSliders from '../ShowSliders/index';

import statesList from '../../Helpers/StatesList';
import multipledevices from '../../../img/multiple-deviceSpring2020.png';

export class PremiumServicesCard extends Component {

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.showInfo.name !== prevState.showInfo.name && nextProps.showInfo.show_abbrev !== prevState.showInfo.show_abbrev) {
            return { showInfo: nextProps.showInfo, formFields: nextProps.showInfo }
        }

        return null;
    }

    constructor(props) {
        super(props);
        this.state = {
            showInfo: this.props.showInfo,
            formFields: '',
            showInstructionModal: false,
            showOrderModal: false,
            showOrderModalForm: true,
            showSponsorshipsSlider: false,
            showLeadRetrievalSendEmailSlider: false
        }
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({ formFields: { ...this.state.formFields, [name]: value } });
    }

    getLeadRetrievalStatus = () => {
        Axios.get(`/api/GetLeadRetrievalStatus`, {
            params: {
                id: Globals.userInfo.companyId,
                showcode: this.props.location?.state?.showcode || Globals.defaultShowCode
            }
        }).then(response => {
            let retval = response.data;

            //console.log("GetLeadRetrievalStatus: " + retval);

            if (retval) {
                this.setState({
                    showInstructionModal: true
                });
            } else {
                this.setState({
                    showOrderModal: true
                });
            }
        }).catch(error => {
            console.log(error);
        });
    }

    hideShowOrderPane = () => {
        this.setState({
            showOrderModal: false,
            formFields: '',
        });
    }

    submitLeadRetrievalOrder = () => {
        if (this.state.formFields.contact && this.state.formFields.telephone && this.state.formFields.email) {
            let onlineOrder = {
                Id: Globals.userInfo.companyId,
                showcode: parseInt(this.props.location?.state?.showcode || Globals.defaultShowCode),
                contact: this.state.formFields.contact,
                phone: this.state.formFields.telephone,
                email: this.state.formFields.email
            };

            let postdata = {
                reqExhibitor: Globals.userInfo.companyName,
                currentuser: Globals.userInfo.username,
                name: this.state.showInfo.name,
                lrprice: '$250',
                onlineOrdersModel: onlineOrder
            }

            Axios.post(`/api/ProcessLeadRetrievalOrder`, postdata
            ).then(response => {
                if (response.data) {
                    this.setState({
                        showOrderModal: false
                    });

                    smallBox({
                        title: "Notice",
                        content: "<i class='fa fa-clock-o'></i> <i>Lead Retrieval Order Submitted - Thank you for your order.</i>",
                        color: "#659265",
                        iconSmall: "fa fa-check fa-2x fadeInRight animated",
                        timeout: 4000
                    });
                }
            }).catch(error => {
                console.log(error);

                smallBox({
                    title: "Notice",
                    content: "<i class='fa fa-clock-o'></i> <i>Something went wrong, please try again later or call Karen C. at 800-225-4535 ext. 138 or <a href=\"mailto: karenc@d2p.com\">karenc@d2p.com</a>.</i>",
                    color: "#C46A69",
                    iconSmall: "fa fa-times fa-2x fadeInRight animated",
                    timeout: 4000
                });
            });
        } else {
            smallBox({
                title: "Notice",
                content: "<i class='fa fa-clock-o'></i> <i>Please fill out all fields.</i>",
                color: "#C46A69",
                iconSmall: "fa fa-times fa-2x fadeInRight animated",
                timeout: 4000
            });
        }

    }

    render() {

        return (
            <Card className="showCard">
                <Card.Header><span>Lead Retrieval & Premium Marketing Services</span></Card.Header>
                <Card.Body>
                    <div className="text-center a">
                        <p onClick={this.getLeadRetrievalStatus}><u>Electronic Lead Retrieval</u></p>
                    </div>
                    <div className="text-center a">
                        <p><a href="http://d2pgraphics.com/" target="_blank" rel="noopener noreferrer" className="font-weight-normal"><u>Marketing For Manufacturers</u></a></p>
                    </div>
                    <div className="text-center a">
                        <p onClick={() => this.setState({ showSponsorshipsSlider: true })}><u>Sponsorships</u></p>
                    </div>
                    <div className="text-center a">
                        <a href="http://d2pmagazine.com/" target="_blank" rel="noopener noreferrer" className="font-weight-normal"><u>Advertising Opportunities</u></a>
                    </div>
                </Card.Body>

                <SlidingPane
                    className='some-custom-class'
                    overlayClassName='showCard'
                    isOpen={this.state.showInstructionModal}
                    title='Electronic Lead Retrieval'
                    onRequestClose={() => this.setState({ showInstructionModal: false })}
                    closeIcon={<i className="fas fa-chevron-right float-left"></i>}
                >
                    <div className="slide-pane-body scrollableDiv">
                        <h1>Electronic Lead Retrieval</h1>

                        <p>Your company is currently signed up to use electronic lead retrieval for the <strong>{this.state.showInfo.name}</strong> show.</p>

                        <p>
                            Please remember to have everyone working the booth download the "D2P Shows" app, for your Apple or Android devices. You are allowed to set up unlimited devices at no additional cost.
                        </p>

                        <p>
                            iOS - <a href="https://itunes.apple.com/us/app/d2p-shows/id845230786?mt=8" target="_blank" rel="noopener noreferrer">https://itunes.apple.com/us/app/d2p-shows/id845230786?mt=8</a>
                            <span style={{ paddingLeft: '20px' }}>Android - <a href="https://play.google.com/store/apps/details?id=com.d2p.d2pshows" target="_blank" rel="noopener noreferrer">https://play.google.com/store/apps/details?id=com.d2p.d2pshows</a></span>
                        </p>

                        <ol>
                            <li>Once downloaded, click on the exhibiting show.</li>
                            <li>Click Lead Retrieval</li>
                            <li>Scan customized QR Code emailed to you (1 week prior to the show)</li>
                        </ol>

                        <p>
                            Your Package Includes:
                            <ul style={{ listStyleType: 'upper-alpha' }}>
                                <li>Unlimited devices - Covers your entire booth staff working the show</li>
                                <li>Access to the show's badge scanner within the app</li>
                                <li>On-site technical assistance during the show</li>
                                <li>View/download your leads here</li>
                            </ul>
                        </p>

                        <p>
                            If you have any additional questions, please contact Mark Schmidt at <a href="mailto:mschmidt@d2p.com"> mschmidt@d2p.com</a> or (800)-225-4535, ext. 112.
                        </p>

                        <p>&nbsp;</p>
                        <div>
                            <img src={multipledevices} className="img-fluid" width="50%" alt="" style={{ border: '0px solid black', maxWidth: 500 }} />
                        </div>
                    </div>

                    <div className="modal-footer">
                        <div className="form-group col-12 padding-25-10">
                            <button className="btn btn-danger btn-lg" onClick={() => this.setState({ showLeadRetrievalSendEmailSlider: true })}>Email Instructions</button>
                            <button type="button" onClick={() => this.setState({ showInstructionModal: false })} className="btn btn-primary btn-lg margin-left-15">Close</button>
                        </div>
                    </div>
                </SlidingPane>

                <SlidingPane
                    className='some-custom-class'
                    overlayClassName='showCard'
                    isOpen={this.state.showOrderModal}
                    title='Electronic Lead Retrieval Order'
                    onRequestClose={this.hideShowOrderPane}
                    closeIcon={<i className="fas fa-chevron-right float-left"></i>}
                >
                    <div className="slide-pane-body scrollableDiv">
                        <div className="panel-content instruction-modal">
                            <h1>Electronic Lead Retrieval</h1>
                            <div className="sub-header-title"><h2 style={{ textDecoration: 'none' }}>Show Rate: $250 Per Company</h2></div>
                            <div>
                                Enhance your lead acquisition process by capturing leads on your own mobile device. Available for Apple iOS products and Android smartphones/tablets. Requires iOS 11.0 or above, and Android 9.0 or higher.
                            </div>
                            <br />
                            <div className="sub-header-title"><h3>What This Includes:</h3></div>

                            <ul>
                                <li>Unlimited devices - Covers your entire booth staff working the show</li>
                                <li>Access to the show's badge scanner within the app</li>
                                <li>On-site technical assistance during the show</li>
                                <li>Custom online portal to login and view/download your leads live</li>
                            </ul>
                            <br />
                            <div>
                                <img src={multipledevices} className="img-fluid" width="50%" alt="" style={{ border: '0px solid black', maxWidth: 500 }} />
                            </div>
                            <br />

                            <div>To order electronic lead retrieval for your company, please fill out your contact information below.</div>
                            <br />

                            <div className="row">
                                <div className="form-group col-lg-12">
                                    <label className="form-label">Contact</label>
                                    <input type="text" name="contact" value={this.state.formFields.contact || ''} onChange={this.handleInputChange} className="form-control-custom" />
                                </div>

                                <div className="form-group col-lg-6">
                                    <label className="form-label">Phone</label>
                                    <NumberFormat className="form-control-custom" name="telephone" value={this.state.formFields.telephone || ''} onChange={this.handleInputChange} format="###-###-####" mask="" />
                                </div>

                                <div className="form-group col-lg-6">
                                    <label className="form-label">Email</label>
                                    <input type="text" name="email" value={this.state.formFields.email || ''} onChange={this.handleInputChange} className="form-control-custom" />
                                </div>
                            </div>

                            <div>
                                If you have any additional questions, please contact Mark Schmidt at
                                <a href="mailto:mschmidt@d2p.com"> mschmidt@d2p.com</a> or (800)-225-4535, ext. 112
                            </div>

                        </div>
                    </div>
                    <div className="modal-footer">
                        <div className="form-group col-12 padding-25-10">
                            <button className="btn btn-danger btn-lg" onClick={this.submitLeadRetrievalOrder}> Submit Order</button>
                            <button type="button" onClick={this.hideShowOrderPane} className="btn btn-primary btn-lg margin-left-15">Close</button>
                        </div>
                    </div>
                </SlidingPane>

                <ShowSliders.SponsorshipsSlider showPane={this.state.showSponsorshipsSlider} showInfo={this.props.showInfo} hidePane={() => this.setState({ showSponsorshipsSlider: false })} {...this.props} />
                <ShowSliders.LeadRetrievalSendEmailSlider showPane={this.state.showLeadRetrievalSendEmailSlider} hidePane={() => this.setState({ showLeadRetrievalSendEmailSlider: false })} {...this.props} />
            </Card>
        );
    }
}