import React, { Component } from 'react';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import NumberFormat from 'react-number-format';
import ellogo from '../../../img/Screengrab_ExhibListLogo.jpg';
import dlhomepg from '../../../img/Screengrab_DirectoryListingHomePg.jpg';
import fphomepg from '../../../img/Screengrab_FloorPlanHomePg.jpg';
import pcat from '../../../img/Screengrab_ProductCategory.jpg';
import lany from '../../../img/Screengrab_lanyards.jpg';
import gbags from '../../../img/Screengrab_bags.jpg';
import { toast, confirm } from '@rickylandino/react-messages';
import Globals from '../../../config/globals';
import Axios from '../../../config/axios';

export class SponsorshipsSlider extends Component {

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.showPane !== prevState.showPane) {
            return nextProps.showPane === true ? { showPane: true } : { showPane: false };
        }
        return null;
    }

    constructor(props) {
        super(props);
        this.state = {
            showInfo: this.props.showInfo,
            showPane: false,
            formFields: {
                contact: '',
                phone: '',
                email: '',
                comments: '',
            },
            sponsorshipType: ''
        }
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({ formFields: { ...this.state.formFields, [name]: value } });
    }

    hidePane = () => {
        this.setState({
            showPane: false
        });

        this.props.hidePane();
    }

    handleSponsorshipTypeChange = (e) => {
        const target = e.target;

        if (target.type === 'radio') {
            this.setState({
                ...this.state,
                sponsorshipType: e.target.value
            });
        }
    }

    handleResetSelections = (e) => {
        e.preventDefault();

        this.setState({
            ...this.state,
            sponsorshipType: '',
            formFields: {}
        });
    }

    handleSubmit = (e) => {
        e.preventDefault();

        //console.log(this.state.formFields);
        //console.log(this.state.sponsorshipType);
        //console.log(this.state.showInfo);

        if (this.state.formFields.contact === '') {
            toast.error("You must specify Contact Name before submitting an inquiry");

            return;
        }

        let postdata = {
            reqExhibitor: Globals.userInfo.companyName,
            name: this.state.formFields.contact,
            email: this.state.formFields.email,
            telephone: this.state.formFields.telephone,
            sponsorshipType: this.state.sponsorshipType,
            showName: this.state.showInfo.name,
            content: this.state.formFields.comments
        }

        console.log(postdata);

        Axios.post(`/api/MSO_ProcessSponsorshipInquiry`, postdata
        ).then(response => {
            if (response.data) {
                this.setState({
                    ...this.state,
                    sponsorshipType: '',
                    formFields: {}
                });

                toast.success("Your sponsorship inquiry was submitted for the " + this.state.showInfo.name + " show ");
            }
        }).catch(error => {
            console.log(error);

            toast.error("There was a problem processing your inquiry for the " + this.state.showInfo.name + " show ");
        });
    }

    render() {

        return (
            <SlidingPane
                className='some-custom-class'
                overlayClassName='showCard'
                isOpen={this.state.showPane}
                title='Sponsorships'
                onRequestClose={this.hidePane}
                closeIcon={<i className="fas fa-chevron-right float-left"></i>}
                width="50%"
            >
                <div className="slide-pane-body scrollableDiv">

                    <div className="row">
                        <div className="col col-12">
                            <h1>Sponsorships</h1>
                            <div>
                                Increase your booth traffic and bring awareness to attendees before, during and after the show! According to the Center for Exhibition Industry Research (CIER),
                                sponsorships can increase booth traffic and interest to your company by over 100%. Maximize your company's Return on Investment (ROI) by including sponsorships
                                to your exhibiting experience.
                            </div>
                            <br />

                            <div class="sub-header-title"><h2 style={{ textDecoration: 'none' }}>Sponsorships include:</h2></div>

                            <ul>
                                <li>Company logo - Exhibitor list (D2P.com)</li>
                                <li>Digital Banners D2P.com (multiple options available)</li>
                                <li>Attendee Lanyards</li>
                                <li>Attendee Tote Bags</li>
                            </ul>
                            <br />


                            <div>
                                <div className="sub-header-title"><h2 style={{ textDecoration: 'none' }}><strong>Company Logo for Exhibitor Listing</strong></h2></div>
                                <div>Companies can add their company logo to their online exhibitor listing. There is a nominal fee of $90.00 per show <em>(A vector image of your logo is required)</em>.</div>
                                <div><br />
                                    <img src={ellogo} className="img-fluid" width="50%" alt="" style={{ border: '2px solid black' }} />
                                </div>
                            </div>
                            <br />

                            <div className="sub-header-title"><h2 style={{ textDecoration: 'none' }}><strong>Digital Banners</strong></h2></div>
                            <div>Place a digital banner on our show website (D2P.com) where OEM attendees view who is exhibiting, research &amp; plan their visit and register to attend the show. All banners include a promotional message and link to your website. The cost to create a digital banner is included with this promotion. Three types of digital banners are available:</div><br />
                            <ul>
                                <li><h3>Directory Listing Homepage Banner:&nbsp;</h3>
		                        <div>This banner is exclusive to only one company per show. It is live 8 weeks prior to the show. This banner is located on the main landing page where attendees plan their visit.</div></li>
                            </ul>
                            <div><img style={{ border: '2px solid black' }} src={dlhomepg} width="50%" alt="" /> </div>
                            <br />
                            <ul>
                                <li><h3>Floor Plan Homepage Banner:&nbsp;</h3>
                                <div>This banner is exclusive to only one company per show. It is live 6 weeks prior to the show. The banner is seen any time an attendee visits our interactive floor plan for planning their visit.</div></li>
                            </ul>
                                <div><img style={{ border: '2px solid black' }} src={fphomepg} width="50%" alt="" /> </div>
                            <br />
                            <ul>
                                <li><h3>Category Banner:&nbsp;</h3>
                                <div>This banner is shown on our Service Categories page. It is located on the main landing page where attendees plan their visit. It is live 8 weeks prior to the show.</div></li>
                            </ul>
                            <div><img style={{ border: '2px solid black' }} src={pcat} width="50%" alt="" /> </div>

                            <br />
                            <div class="sub-header-title"><h2 style={{ textDecoration: 'none' }}>Attendee Lanyards</h2></div>
                            <div>Lanyards are handed out to every attendee that attends the show. They are an inexpensive way to help with your company's branding. They offer high visibility throughout the show and become a take home item for all attendees. There is a variety of lanyard materials and ink colors to choose from. The cost for each show runs between $1,500 to $2,500 and is based on the number of attendees. Sponsor one show or choose a multi-show package for a volume discount.<br /></div><br />

                            <div><img style={{ border: '2px solid black' }} src={lany} width="50%" alt="" /></div>


                            <br />
                            <div class="sub-header-title"><h2 style={{ textDecoration: 'none' }}>Attendee Tote Bags</h2></div>
                            <div>A sponsorship of attendee bags imprinted with your company name and logo are handed out to every attendee that visits the show. These bags are an inexpensive way to help with your company's branding. They offer high visibility throughout the show and become a take home item for the attendees <em>(Offered as a multi-show sponsorship only)</em>.</div>

                            <br />
                            <div><img style={{ border: '2px solid black' }} src={gbags} width="50%" alt="" /></div>

                            <br />
                            <div>
                                If you do not see a sponsorship you had in mind, we welcome all suggestions. If you have a specific budget in mind for promotional items, we would be happy to create a package to suit your needs. Please fill out the below and click Submit for detailed information.
                            </div>
                            <div>&nbsp;</div>

                            <div className="row">
                                <div className="form-group col-lg-4">
                                    <label className="form-label">Contact</label>
                                    <input type="text" name="contact" value={this.state.formFields.contact || ''} onChange={this.handleInputChange} className="form-control-custom" />
                                </div>
                                <div className="form-group col-lg-8"></div>

                                <div className="form-group col-lg-3">
                                    <label className="form-label">Phone</label>
                                    <NumberFormat className="form-control-custom" name="telephone" value={this.state.formFields.telephone || ''} onChange={this.handleInputChange} format="###-###-####" mask="" />
                                </div>
                                <div className="form-group col-lg-5">
                                    <label className="form-label">Email</label>
                                    <input type="text" name="email" value={this.state.formFields.email || ''} onChange={this.handleInputChange} className="form-control-custom" />
                                </div>
                                <div className="form-group col-lg-4"></div>

                                <div className="form-group form-inline col-lg-12" onChange={this.handleSponsorshipTypeChange}>
                                    <label className="form-label">Sponsorship Type:</label>&nbsp;&nbsp;
                                    <input type="radio" name="sponsorshipType" value="Company Logo" checked={this.state.sponsorshipType === "Company Logo"} />&nbsp;<label className="form-label">Company Logo</label>&nbsp;&nbsp;&nbsp;
                                    <input type="radio" name="sponsorshipType" value="Digital Banner" checked={this.state.sponsorshipType === "Digital Banner"} />&nbsp;<label className="form-label">Digital Banner</label>&nbsp;&nbsp;&nbsp;
                                    <input type="radio" name="sponsorshipType" value="Attendee Lanyards" checked={this.state.sponsorshipType === "Attendee Lanyards"} />&nbsp;<label className="form-label">Attendee Lanyards</label>&nbsp;&nbsp;&nbsp;
                                    <input type="radio" name="sponsorshipType" value="Attendee Tote Bag" checked={this.state.sponsorshipType === "Attendee Tote Bag"} />&nbsp;<label className="form-label">Attendee Tote Bag</label>&nbsp;&nbsp;&nbsp;
                                </div>
                                <div className="form-group col-lg-8"></div>

                                <div className="form-group col-lg-12">
                                    <label className="form-label">Comments</label>
                                    <textarea id="comments" name="comments" rows="4" cols="50" value={this.state.formFields.comments || ''} onChange={this.handleInputChange} className="form-control-custom" />
                                </div>

                                <div className="col-lg-8">
                                    <button className="btn btn-danger btn-lg" onClick={this.handleSubmit}> Submit</button>

                                    <span className="form-group col-lg-2 a" onClick={this.handleResetSelections}><u>Reset Selections</u></span>
                                </div>
                                <div className="form-group col-lg-4"></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal-footer">
                    <div className="form-group col-12 padding-25-10">
                        <button className="btn btn-primary btn-lg margin-left-15" onClick={this.hidePane}>Close</button>
                    </div>
                </div>
            </SlidingPane>
        );
    }
}