import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Axios from '../../config/axios';
import Globals from '../../config/globals';
import spinnerLarge from '../../img/spinnerLarge.gif';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import { Fragment } from 'react';
import { smallBox, SmartMessageBox } from "../../common/utils/functions";
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import NumberFormat from 'react-number-format';
import statesList from '../Helpers/StatesList';
import { reloadCompany } from '../../common/services/CompanyService';
import { takeWhile } from 'rxjs/operators';
import { reloadLayout } from '../../common/services/CompanyService';

class ExhibitorHome extends Component {

    constructor(props) {
        super(props);
        this.state = {
            mainContactInfo: {},
            logisticsContactInfo: {},
            showPane: false,
            formFields: {},
            states: statesList,
            processingSubmitOrder: false
        }

        this.alive = false;
    }

    //**********************************
    //Original methods                 *
    //**********************************

    //componentDidMount() {
    //    Axios.get(`/api/GetContactInfoByCompanyId`, {
    //        params: {
    //            id: this.props.userInfo.companyId
    //        }
    //    }).then(response => {
    //        let retval = response.data;

    //        for (var i = 0; i < retval.length; i++) {
    //            if (retval[i].contact_type === 'Logistics') {
    //                this.setState({
    //                    logisticsContactInfo: retval[i]
    //                });
    //            } else if (retval[i].contact_type === 'Main') {
    //                this.setState({
    //                    mainContactInfo: retval[i]
    //                });
    //            }
    //        }

    //        this.setFormFields();
    //    }).catch(error => {
    //        console.log(error);
    //    });
    //}

    //componentDidUpdate(prevProps, prevState) {

    //}

    componentWillUnmount() {
        this.alive = false;
    }

    componentDidMount() {
        this.alive = true;

        this.reloadCompanyInfo();
        this.reloadContactsInfo();

        if (this.alive) {
            reloadCompany.pipe(takeWhile(() => this.alive)).subscribe(response => {
                if (response) {
                    this.reloadCompanyInfo();
                }
            });
        }

        reloadLayout.next(true);
    }

    componentDidUpdate(prevProps, prevState) {
        //if the selected company changed then reload from DB
        //if (this.props.userInfo.companyId !== this.props.userInfo.customerInfo.id) {
        //    this.reloadCompanyInfo();
        //}
    }

    reloadCompanyInfo = () => {
        Axios.get(`/api/GetAdminCompanyInfo`, {
            params: {
                id: this.props.userInfo.companyId
            }
        }).then(response => {
            let userInfo = Globals.userInfo;
            userInfo.customerInfo = response.data[0].customer;
            Globals.userInfo = userInfo;
            window.sessionStorage.setItem("userInfo", JSON.stringify(userInfo));

            this.reloadContactsInfo();

        }).catch(error => {
            console.log(error);
        });
    }

    reloadContactsInfo = () => {
        Axios.get(`/api/GetContactInfoByCompanyId`, {
            params: {
                id: this.props.userInfo.companyId
            }
        }).then(response => {
            let retval = response.data;

            for (var i = 0; i < retval.length; i++) {
                if (retval[i].contact_type === 'Logistics') {
                    this.setState({
                        logisticsContactInfo: retval[i]
                    });
                } else if (retval[i].contact_type === 'Main') {
                    this.setState({
                        mainContactInfo: retval[i]
                    });
                }
            }

            this.setFormFields();
        }).catch(error => {
            console.log(error);
        });
    }

    setFormFields = () => {
        let formFields = this.props.userInfo.customerInfo;
        if (this.state.logisticsContactInfo.contact_type) {
            formFields.logisticsContact = this.state.logisticsContactInfo.jsS_name;
            formFields.logisticsContactEmail = this.state.logisticsContactInfo.email;
            formFields.logisticsContactPhone = this.state.logisticsContactInfo.phone;
        }
        if (this.state.mainContactInfo.contact_type) {
            formFields.mainContact = this.state.mainContactInfo.jsS_name;
            formFields.mainContactEmail = this.state.mainContactInfo.email;
            formFields.mainContactPhone = this.state.mainContactInfo.phone;
        }



        this.setState({
            formFields
        });
    }

    hidePane = () => {
        this.setFormFields();
        this.setState({
            showPane: false
        });
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({ formFields: { ...this.state.formFields, [name]: value } });
    }

    handleSubmit = () => {
        this.setState({
            processingSubmitOrder: true
        });

        let whatChangedText = "";
        let formFields = this.state.formFields;
        let prevInfo = this.props.userInfo.customerInfo;

        //console.log(formFields);
        //console.log(prevInfo);

        if (formFields.name !== prevInfo.name) {
            whatChangedText += "Company Name changed from '" + prevInfo.name + "' to '" + formFields.name + "' <br />";
        }
        if (formFields.street1 !== prevInfo.street1) {
            whatChangedText += "Address 1 changed from '" + prevInfo.street1 + "' to '" + formFields.street1 + "' <br />";
        }
        if (formFields.street2 !== prevInfo.street2) {
            whatChangedText += "Address 2 changed from '" + prevInfo.street2 + "' to '" + formFields.street2 + "' <br />";
        }
        if (formFields.city !== prevInfo.city) {
            whatChangedText += "City changed from '" + prevInfo.city + "' to '" + formFields.city + "' <br />";
        }
        if (formFields.state !== prevInfo.state) {
            whatChangedText += "State changed from '" + prevInfo.state + "' to '" + formFields.state + "' <br />";
        }
        if (formFields.zip !== prevInfo.zip) {
            whatChangedText += "Zip changed from '" + prevInfo.zip + "' to '" + formFields.zip + "' <br />";
        }
        if (formFields.domain !== prevInfo.domain) {
            whatChangedText += "Website changed from '" + prevInfo.domain + "' to '" + formFields.domain + "' <br />";
        }
        if (formFields.telephone !== prevInfo.telephone) {
            whatChangedText += "Telephone changed from '" + prevInfo.telephone + "' to '" + formFields.telephone + "' <br />";
        }
        if (formFields.fax !== prevInfo.fax) {
            whatChangedText += "Fax changed from '" + prevInfo.fax + "' to '" + formFields.fax + "' <br />";
        }

        if (formFields.logisticsContact !== prevInfo.logisticsContact) {
            whatChangedText += "Logistics Contact changed from '" + prevInfo.logisticsContact + "' to '" + formFields.logisticsContact + "' <br />";
        }
        if (formFields.logisticsContactEmail !== prevInfo.logisticsContactEmail) {
            whatChangedText += "Logistics Contact Email changed from '" + prevInfo.logisticsContactEmail + "' to '" + formFields.logisticsContactEmail + "' <br />";
        }
        if (formFields.logisticsContactPhone !== prevInfo.logisticsContactPhone) {
            whatChangedText += "Logistics Contact Phone changed from '" + prevInfo.logisticsContactPhone + "' to '" + formFields.logisticsContactPhone + "' <br />";
        }

        if (formFields.mainContact !== prevInfo.mainContact) {
            whatChangedText += "Main Contact changed from '" + prevInfo.mainContact + "' to '" + formFields.mainContact + "' <br />";
        }
        if (formFields.mainContactEmail !== prevInfo.mainContactEmail) {
            whatChangedText += "Main Contact Email changed from '" + prevInfo.mainContactEmail + "' to '" + formFields.mainContactEmail + "' <br />";
        }
        if (formFields.mainContactPhone !== prevInfo.mainContactPhone) {
            whatChangedText += "Main Contact Phone changed from '" + prevInfo.mainContactPhone + "' to '" + formFields.mainContactPhone + "' <br />";
        }

        if (whatChangedText === "") {
            this.setState({
                processingSubmitOrder: false
            });

            smallBox({
                title: "Notice",
                content: "<i class='fa fa-clock-o'></i> <i>No Changes Were Made</i>",
                color: "#C46A69",
                iconSmall: "fa fa-times fa-2x fadeInRight animated",
                timeout: 4000
            });
        } else {

            let postdata = {
                whatChangedText,
                reqExhibitor: Globals.userInfo.companyName,
                id: Globals.userInfo.companyId.toString(),
                email: Globals.userInfo.username
            }

            Axios.post(`/api/SendCompanyProfileChangeRequest`, postdata
            ).then(response => {
                this.setState({
                    processingSubmitOrder: false
                });

                if (response.data === true) {
                    smallBox({
                        title: "Notice",
                        content: "<i class='fa fa-clock-o'></i> <i>Change request submitted.</i>",
                        color: "#659265",
                        iconSmall: "fa fa-check fa-2x fadeInRight animated",
                        timeout: 4000
                    });

                    this.hidePane();
                } else {
                    smallBox({
                        title: "Notice",
                        content: "<i class='fa fa-clock-o'></i> <i>Error: Changes not submitted</i>",
                        color: "#C46A69",
                        iconSmall: "fa fa-times fa-2x fadeInRight animated",
                        timeout: 4000
                    });
                }
            }).catch(error => {
                console.log(error);
            });
        }
    }

    render() {
        return (
            <Fragment>
                <h1>Company Profile</h1>
                
                <div className="address">
                    {this.props.userInfo.customerInfo &&
                        <Fragment>
                            <h2>Company Information</h2>
                            <div>{this.props.userInfo.customerInfo.street1}</div>
                            <div>{this.props.userInfo.customerInfo.city}, {this.props.userInfo.customerInfo.state} {this.props.userInfo.customerInfo.zip}</div>
                            <br />
                        <div><strong>Tel:</strong> {this.props.userInfo.customerInfo.telephone}</div>
                            <div><strong>Fax:</strong> {this.props.userInfo.customerInfo.fax}</div>
                                <div><strong>Domain:</strong> <a target="_blank" rel="noopener noreferrer" href={this.props.userInfo.customerInfo.domain}>{this.props.userInfo.customerInfo.domain}</a></div>
                        </Fragment>
                    }
                </div>

                <div className="address">
                    <h2>Contact Information</h2>
                    {this.state.logisticsContactInfo.contact_type &&
                        <Fragment>
                            <div><strong>Logistics Contact:</strong> {this.state.logisticsContactInfo.jsS_name}</div>
                            <div><strong>Logistics Contact Email:</strong> {this.state.logisticsContactInfo.email}</div>
                            <div><strong>Logistics Contact Telephone:</strong> {this.state.logisticsContactInfo.phone}</div>
                            <br />
                        </Fragment>
                    }
                    {this.state.mainContactInfo.contact_type &&
                        <Fragment>
                            <div><strong>Main Contact:</strong> {this.state.mainContactInfo.jsS_name}</div>
                            <div><strong>Main Contact Email:</strong> {this.state.mainContactInfo.email}</div>
                            <div><strong>Main Contact Telephone:</strong> {this.state.mainContactInfo.phone}</div>
                        </Fragment>
                    }

                </div>

                <div className="address">
                    <label className="form-label color-d2p-internal-use-blue">If this information is correct, no further action is required here</label><br />
                </div>

                <div className="address">
                    <button className="btn btn-warning btn-lg" onClick={() => this.setState({ showPane: true })}>Edit Company Profile</button>
                </div>
                <div className="address">
                    <label className="form-label color-d2p-internal-use">Choose your exhibiting show - left hand side of screen</label><br />
                </div>

                <SlidingPane
                    className='some-custom-class'
                    overlayClassName='pinkCard'
                    isOpen={this.state.showPane}
                    title='Edit Company Profile'
                    onRequestClose={() => this.setState({ showPane: false })}
                    closeIcon={<i className="fas fa-chevron-right float-left"></i>}
                >
                    <div className="slide-pane-body scrollableDiv">
                        <div className="row">
                            <div className="form-group col-lg-12">
                                <label className="form-label color-d2p-internal-use">Internal (D2P) Use Only, not published</label><br />
                            </div>
                            <div className="form-group col-lg-12">
                                <label className="form-label">Company Name</label>
                                <input type="text" name="name" value={this.state.formFields.name || ''} onChange={this.handleInputChange} className="form-control-custom" />
                            </div>

                            <div className="form-group col-lg-6">
                                <label className="form-label">Address 1</label>
                                <input type="text" name="street1" value={this.state.formFields.street1 || ''} onChange={this.handleInputChange} className="form-control-custom" />
                            </div>

                            <div className="form-group col-lg-6">
                                <label className="form-label">Address 2</label>
                                <input type="text" name="street2" value={this.state.formFields.street2 || ''} onChange={this.handleInputChange} className="form-control-custom" />
                            </div>

                            <div className="form-group col-lg-4">
                                <label className="form-label">City</label>
                                <input type="text" name="city" value={this.state.formFields.city || ''} onChange={this.handleInputChange} className="form-control-custom" />
                            </div>

                            <div className="form-group col-lg-4">
                                <label className="form-label">State</label>
                                <select className="form-control-custom" value={this.state.formFields.state} name="state" onChange={this.handleInputChange}>
                                    <option></option>
                                    {this.state.states.map((state, idx) => <option key={idx} value={state.abbreviation}>{state.name}</option>)}
                                </select>
                            </div>

                            <div className="form-group col-lg-4">
                                <label className="form-label">Zip</label>
                                <input type="text" name="zip" value={this.state.formFields.zip || ''} onChange={this.handleInputChange} className="form-control-custom" />
                            </div>

                            <div className="form-group col-lg-4">
                                <label className="form-label">Phone</label>
                                <NumberFormat className="form-control-custom" name="telephone" value={this.state.formFields.telephone || ''} onChange={this.handleInputChange} format="###-###-####" mask="_" />
                            </div>

                            <div className="form-group col-lg-4">
                                <label className="form-label">Fax</label>
                                <NumberFormat className="form-control-custom" name="fax" value={this.state.formFields.fax || ''} onChange={this.handleInputChange} format="###-###-####" mask="_" />
                            </div>

                            <div className="form-group col-lg-4">
                                <label className="form-label">Domain</label>
                                <input type="text" name="domain" value={this.state.formFields.domain || ''} onChange={this.handleInputChange} className="form-control-custom" />
                            </div>
                            <div className="col-12">
                                <h5 className="label-with-strike">Contact Information</h5>
                            </div>
                            <div className="form-group col-lg-4">
                                <label className="form-label">Logistics Contact</label>
                                <input type="text" name="logisticsContact" value={this.state.formFields.logisticsContact || ''} onChange={this.handleInputChange} className="form-control-custom" />
                            </div>

                            <div className="form-group col-lg-4">
                                <label className="form-label">Logistics Contact Phone</label>
                                <NumberFormat className="form-control-custom" name="logisticsContactPhone" value={this.state.formFields.logisticsContactPhone || ''} onChange={this.handleInputChange} format="###-###-####" mask="_" />
                            </div>

                            <div className="form-group col-lg-4">
                                <label className="form-label">Logistics Contact Email</label>
                                <input type="text" name="logisticsContactEmail" value={this.state.formFields.logisticsContactEmail || ''} onChange={this.handleInputChange} className="form-control-custom" />
                            </div>

                            <div className="form-group col-lg-4">
                                <label className="form-label">Main Contact</label>
                                <input type="text" name="mainContact" value={this.state.formFields.mainContact || ''} onChange={this.handleInputChange} className="form-control-custom" />
                            </div>

                            <div className="form-group col-lg-4">
                                <label className="form-label">Main Contact Phone</label>
                                <NumberFormat className="form-control-custom" name="mainContactPhone" value={this.state.formFields.mainContactPhone || ''} onChange={this.handleInputChange} format="###-###-####" mask="_" />
                            </div>

                            <div className="form-group col-lg-4">
                                <label className="form-label">Main Contact Email</label>
                                <input type="text" name="mainContactEmail" value={this.state.formFields.mainContactEmail || ''} onChange={this.handleInputChange} className="form-control-custom" />
                            </div>

                            
                            
                        </div>
                    </div>

                    <div className="modal-footer">
                        <div className="form-group col-12 padding-25-10">
                            <button className="btn btn-danger btn-lg" onClick={this.handleSubmit} disabled={this.state.processingSubmitOrder}>Submit Change Request</button>
                            <button className="btn btn-primary btn-lg margin-left-15" onClick={this.hidePane}>Close</button>
                            {this.state.processingSubmitOrder &&
                                <img src={spinnerLarge} alt="D2P Busy" className="margin-left-15" style={{ height: '30px', width: '30px' }} />
                            }
                        </div>
                    </div>
                </SlidingPane>
            </Fragment>
        );
    }
}

export default withRouter(ExhibitorHome);